import * as React from "react"
import Layout from "../../components/layout"
import BannerUniformShop from "../../components/about/bannerUniformShop";
import ByodContent from "../../components/about/byodContent";


const ByodPage = () => {
    return (
        <Layout>
            <BannerUniformShop/>
            <ByodContent/>
        </Layout>
    )
}

export default ByodPage
