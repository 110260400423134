import React from "react";

import "./aboutContent.css"
import byodChecklist from "../../assets/global/byod-check-list.pdf";

const ByodContent = () => (
    <div className="container">
        <div className={"content aboutContent"}>

            <h2>Bring Your Own Device Program (BYOD)
            </h2>

            <p>All students from Years 3-12 are required to have a BYOD device with them each day.</p>
            <p>Research shows that students experience greater motivation to learn and a significant increase in engagement with the teaching and learning process, when technology is integrated into curriculum.
            </p>

            <p>To facilitate our BYOD program, the College is providing an online purchase portal from a leading education technology supplier, where you will be able purchase a BYOD device with suitable warranty.
            </p> <p>  <a href="https://stmarouns.orderportal.com.au/Welcome?ofid=374&eid=24334&DTID=793">Click here for more information</a></p>


            <p>We have chosen particular laptop models based on hardware specification, battery run time, weight, size, and proven reliability in BYOD laptop programs. To ensure your child is equipped with the right technology in the classroom, we strongly recommend purchasing from the online portal. Please note Apple Mac are not recommended.</p>

            <p>If you wish to purchase from another supplier, we have compiled a BYOD device checklist that outlines specific requirements. Only devices that meet the specifications set out on the hardware checklist will be approved. This is important, to ensure the device is compatible with our College network and curriculum technologies, the requirements are summarised here
            </p>

            <h4>
                <a href={byodChecklist}>BYOD Checklist</a>
            </h4>
            <p />

            <p>If you have further questions You may contact the IT Manager on 9559 2434 or email  <a href="mail:+it.support@stmarouns.nsw.edu.au">
                it.support@stmarouns.nsw.edu.au
            </a>.
            </p>
            <p>We look forward to the positive outcomes and enhanced learning experience that our BYOD program will bring for your child at Saint Maroun’s College.
            </p>

            <p>Kindest Regards,
            </p>

            <p>Sr Margaret Ghosn MSHF</p>
            <p>Principal</p>

        </div>
    </div>
)

export default ByodContent
